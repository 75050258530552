// src/App.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './App.css';

const App = () => {
  const [feedData, setFeedData] = useState([]);
  let wakeLock = null;
  const requestWakeLock = async () => {
    try {
      if ('wakeLock' in navigator) {
        wakeLock = await navigator.wakeLock.request('screen');
        wakeLock.addEventListener('release', () => {
          console.log('released');
        });
        console.log('activated');
      }
    } catch (err) {
      console.error(`${err.name}, ${err.message}`);
    }
  };

  document.addEventListener('visibilitychange', async () => {
    if (wakeLock !== null && document.visibilityState === 'visible') {
      requestWakeLock();
    }
  });

  useEffect(() => {
    const getRSSFeed = async () => {
      const url = encodeURIComponent('https://rss.orf.at/news.xml');
      const rss2jsonUrl = `https://api.rss2json.com/v1/api.json?rss_url=${url}`;
      const { data } = await axios.get(rss2jsonUrl);
      setFeedData(data.items);
      requestWakeLock();
    };

    getRSSFeed();
  }, []);

  return (
    <div className="App">
      <h1>Aktuelle Nachrichten</h1>
      <div className="feed-container">
        {feedData.map((item, index) => (
          <div key={index} className="feed-item">
            <h2>{item.title}</h2>
            <p>{item.description}</p>
            <a href={item.link} target="_blank" rel="noopener noreferrer">
              Read more
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default App;
